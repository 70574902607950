import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: "index",
        component: Layout,
        redirect: '/index',
        children: [
            {
                path: '/index',
                component: () => import("@/pages/index.vue")
            },
            {
                path: '/list',
                component: () => import("@/pages/list.vue")
            },
            {
                path: "/online",
                component: () => import("@/pages/online.vue"),
            },
            {
                path: "/about",
                component: () => import("@/pages/about.vue"),
            },
        ]
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     console.log('beforeEach', to, from);
    
//     next()
// })

export default router