<template>
  <div class="layout">
    <Header />
    <div class="banner">
      <el-carousel class="carousel">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img class="picture" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <router-view>
        <template #default="{ Component }">
          <components :is="Component"></components>
        </template>
      </router-view>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      bannerList: [
        require('@/assets/images/banner.png'),
        require('@/assets/images/banner1.png'),
        require('@/assets/images/banner2.png'),
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    .banner {
      flex-shrink: 0;
      height: 480px;
      .carousel {
        height: 100%;
        /deep/.el-carousel__container {
          height: 100%;
        }
      }
      .picture {
        width: 100%;
        height: 100%;
      }
    }
    .main {
      flex: 1;
      padding-bottom: 40px;
      clear: both;
    }
  }
</style>