<template>
  <div class="header">
    <div
      :class="['header-item', current === item.path ? 'active' : '']"
      v-for="item in list"
      :key="item.path"
      @click="handleClickToView(item)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: '/index',
      list: [
        {
          title: '首页',
          path: '/index',
        },
        {
          title: '仓库列表',
          path: '/list'
        },
        {
          title: '在线留言',
          path: '/online'
        },
        {
          title: '关于我们',
          path: '/about'
        },
      ],
    }
  },
  methods: {
    handleClickToView(val) {
      if (val.path === this.current) return

      this.current = val.path
      this.$router.push(val.path)
    }
  },
  created() {
    this.current = this.$route.path || '/'    
  }
}
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    justify-content: center;
    height: 80px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
    .active {
      color: #E12A36;
    }
    .header-item {
      min-width: 200px;
      height: 100%;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
    }
  }
</style>