const HOST_lIST =[
  {
    host: 'www.192.168.1.208:8081',
    content: '郴州大帅哥贸易有限公司成立于2021-10-18，所属行业为零售业，经营范围包含：许可项目:互联网信息服务（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）一般项目:互联网销售（除销售需要许可的商品）;日用百货销售;鞋帽零售;箱包销售;钟表销售;眼镜销售（不含隐形眼镜）;针纺织品销售;服装辅料销售;皮革销售;皮革制品销售;化妆品零售;化妆品批发;个人卫生用品销售;家用电器销售;机械设备销售;五金产品批发;五金产品零售;橡胶制品销售;办公用品销售;体育用品及器材零售;体育用品及器材批发;工艺美术品及收藏品批发（象牙及其制品除外）;工艺美术品及礼仪用品销售（象牙及其制品除外）;玩具销售;珠宝首饰批发;珠宝首饰零售;灯具销售;建筑装饰材料销售;鲜肉零售;鲜肉批发;农副产品销售;宠物食品及用品批发;宠物食品及用品零售;渔具销售;户外用品销售;服装、服饰检验、整理服务;服装服饰批发;服装服饰零售;纸制品销售（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022023006号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa33.com',
    content: '郴州大帅哥贸易有限公司成立于2021-10-18，所属行业为零售业，经营范围包含：许可项目:互联网信息服务（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）一般项目:互联网销售（除销售需要许可的商品）;日用百货销售;鞋帽零售;箱包销售;钟表销售;眼镜销售（不含隐形眼镜）;针纺织品销售;服装辅料销售;皮革销售;皮革制品销售;化妆品零售;化妆品批发;个人卫生用品销售;家用电器销售;机械设备销售;五金产品批发;五金产品零售;橡胶制品销售;办公用品销售;体育用品及器材零售;体育用品及器材批发;工艺美术品及收藏品批发（象牙及其制品除外）;工艺美术品及礼仪用品销售（象牙及其制品除外）;玩具销售;珠宝首饰批发;珠宝首饰零售;灯具销售;建筑装饰材料销售;鲜肉零售;鲜肉批发;农副产品销售;宠物食品及用品批发;宠物食品及用品零售;渔具销售;户外用品销售;服装、服饰检验、整理服务;服装服饰批发;服装服饰零售;纸制品销售（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022023006号-1',
    img: '',
    domain2: '湘公网安备 43100302000290号',
  },
  {
    host: 'www.11fahuo.com',
    content: '郴州咖菲电子商务有限公司成立于2018-12-03，所属行业为零售业，经营范围包含：商品零售贸易（许可审批类商品除外）；化妆品及卫生用品零售；水晶饰品零售；办公设备耗材零售；玩具零售；婴儿用品零售；帽零售；鞋零售；箱、包零售；纺织品及针织品零售；服装零售；百货零售（食品零售除外）；家具零售；灯具零售；工艺美术品零售（象牙及其制品除外）；计算机零配件零售；电子产品零售；电子元器件零售；开关、插座、接线板、电线电缆、绝缘材料零售；陶瓷装饰材料零售；卫生洁具零售；陶瓷、玻璃器皿零售；厨房用具及日用杂品零售；日用杂品综合零售；日用灯具零售；互联网商品零售（许可审批类商品除外）；照相器材零售。',
    domain: '湘ICP备2022022537号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.55fahuo.com',
    content: '郴州市濮乐贸易有限公司成立于2019-12-27，所属行业为批发业，经营范围包含：服装、鞋帽、床上用品、家具、箱包、体育用品（不含弩）、玩具、工艺品（不含文物、象牙及制品）、建筑材料（不含危险化学品）、日用品、化妆品、文具、五金产品、机械设备、皮革制品、餐具、陶瓷用品、电子产品、珠宝饰品、汽车零配件、家用电器、办公用品、预包装食品销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）。',
    domain: '湘ICP备2022022561号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.591fahuo.com',
    content: '郴州喜欣贸易有限公司成立于2017-02-07，所属行业为批发业，经营范围包含：国内贸易，网上贸易代理．（依法须经批准的项目，经相关部门批准后方可开展经营活动）。',
    domain: '湘ICP备2022022563号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.618fahuo.com',
    content: '郴州霖琳贸易有限公司成立于2020-01-06，所属行业为批发业，经营范围包含：商品批发贸易（许可审批类商品除外）;电子产品批发;五金产品批发;家具批发;箱、包批发;化妆品批发;帽批发;鞋批发;服装批发;纺织品、针织品及原料批发;电子产品零售;五金零售;商品零售贸易（许可审批类商品除外）;纺织品及针织品零售;鞋零售;帽零售;化妆品零售;箱、包零售;服装零售。',
    domain: '湘ICP备2022022557号-1',
    img: '',
  },
  {
    host: 'www.88fahuo.com',
    content: '郴州市蓬橙贸易有限公司成立于2019-11-06，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022022556号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.jiufahuo.com',
    content: '郴州翌帛贸易有限公司成立于2016-12-21，所属行业为批发业，经营范围包含：皮革制品、箱包、床上用品、玩具、鞋帽、针纺织品、服装辅料、服装服饰、日用百货、五金交电、电子产品、化妆品、文具用品、工艺礼品的批发、零售,服装设计,服装服饰、鞋帽、针纺织品的制造、加工（以上限分支机构经营）,从事计算机科技、网络科技领域内的技术开发、技术咨询、技术服务、技术转让。 【依法须经批准的项目,经相关部门批准后方可开展经营活动】。',
    domain: '湘ICP备2022022560号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.nifahuo.com',
    content: '郴州市润究电子商务有限公司成立于2020-07-27，所属行业为互联网和相关服务，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市润究电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022559号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.tadaifa.com',
    content: '郴州汉泓康商贸有限公司成立于2020-01-17，所属行业为零售业，经营范围包含：服装批发;服装零售;纺织品、针织品及原料批发;箱、包零售;鞋零售;帽零售;电子产品零售;电子元器件零售;电子产品批发;电子元器件批发;日用家电设备零售;通信设备零售;文具用品零售;五金零售;厨房设备及厨房用品批发;通讯设备及配套设备批发;保健食品零售（具体经营项目以《食品经营许可证》为准）;预包装食品零售;网络销售预包装食品;保健食品批发（具体经营项目以《食品经营许可证》为准）。',
    domain: '湘ICP备2022022558号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.tafahuo.com',
    content: '郴州嘻唰唰贸易有限公司成立于2021-10-18，所属行业为零售业，经营范围包含：许可项目:食品互联网销售。（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）一般项目:互联网销售（除销售需要许可的商品）;建筑材料销售;办公用品销售;五金产品零售;电子产品销售;服装服饰零售;宠物食品及用品零售;纸制品销售;玩具销售;软件开发。（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022022587号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.818daifa.com',
    content: '郴州市茹涵网络科技有限公司成立于2020-08-27，所属行业为软件和信息技术服务业，经营范围包含：计算机网络技术研发；研发、销售：计算机软硬件；销售：电子产品、五金交电、汽车用品及零配件、饰品、日用品、家居用品、服装、服饰及配件、办公用品及耗材。（依法须经批准的项目，经相关部门批准后方可开展经营活动）。',
    domain: '湘ICP备2022022555号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.4daifa.com',
    content: '郴州市蓬橙贸易有限公司成立于2019-11-06，统一社会信用代码为91330782MA2EDKR946。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A07房，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市蓬橙贸易有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022556号-2',
    img: '',
    domain2: '湘公网安备 43100302000300号',
    name: '郴州市蓬橙贸易有限公司',
    date: '2022-12-26',
    title: '蓬橙贸易',
  },
  {
    host: 'www.9daifa.com',
    content: '郴州乔玲晟商贸有限公司成立于2019-12-23，统一社会信用代码为91320303MA20NH2PXC。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师15A01，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州乔玲晟商贸有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa2.com',
    content: '郴州市润究电子商务有限公司成立于2020-07-27，统一社会信用代码为91431002MA4RJ7KX94。企业地址位于湖南省郴州市北湖区丽景社区樟木栊小区8栋202，所属行业为互联网和相关服务，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市润究电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022559号-2',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa4.com',
    content:
      '郴州大帅哥贸易有限公司成立于2021-10-18，统一社会信用代码为91431003MA7BX6MT3L。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A06房，所属行业为零售业，经营范围包含：许可项目:互联网信息服务（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）一般项目:互联网销售（除销售需要许可的商品）;日用百货销售;鞋帽零售;箱包销售;钟表销售;眼镜销售（不含隐形眼镜）;针纺织品销售;服装辅料销售;皮革销售;皮革制品销售;化妆品零售;化妆品批发;个人卫生用品销售;家用电器销售;机械设备销售;五金产品批发;五金产品零售;橡胶制品销售;办公用品销售;体育用品及器材零售;体育用品及器材批发;工艺美术品及收藏品批发（象牙及其制品除外）;工艺美术品及礼仪用品销售（象牙及其制品除外）;玩具销售;珠宝首饰批发;珠宝首饰零售;灯具销售;建筑装饰材料销售;鲜肉零售;鲜肉批发;农副产品销售;宠物食品及用品批发;宠物食品及用品零售;渔具销售;户外用品销售;服装、服饰检验、整理服务;服装服饰批发;服装服饰零售;纸制品销售（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。。郴州大帅哥贸易有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa98.com',
    content: '个人学习、展示网站',
    domain: '',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa45.com',
    content: '郴州市百盈电子商务有限公司成立于2017-12-28，统一社会信用代码为91321302MA1UTP2T2K。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A08房，所属行业为零售业，经营范围包含：网络技术开发、推广、销售;软件技术开发;预包装食品 （不含冷藏冷冻食品）、散装食品（不含冷藏冷冻食品）销售（含网上销售）;初级农产品收购（粮食除外）;办公用品、体育用品、家具销售（含网上销售）;日用品、服装、鞋帽、床上用品、电子产品、美容电器设备、体育用品、保健食品销售（含网上销售）;图书零售。（依法须经批准的项目,经相关部门批准后方可开展经营活动）。郴州市百盈电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017365号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa34.com',
    content: '郴州昔飞贸易有限公司成立于2021-05-29，统一社会信用代码为91440101MA9XUC1E04。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A11房，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州昔飞贸易有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017254号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa35.com',
    content: '郴州帕维妍服装有限公司成立于2020-09-29，统一社会信用代码为91420117MA49KQ5H3B。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A07房，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;软件开发（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州帕维妍服装有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017376号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa67.com',
    content: '郴州市佰丽电子商务有限公司成立于2011-04-13统一社会信用代码为91330782572933471R。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师15A01房，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市佰丽电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017440号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.daifa98.com',
    content: '个人试验、学习网站',
    domain: '湘ICP备2023018085号-1',
    img: '',
    domain2: '',
  },
  {
    host: 'www.013daifa.com',
    content: '郴州市咖菲电子商务有限公司成立于2018-12-03，统一社会信用代码为91440101MA5CK5E41H。企业地址位于郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A03房，所属行业为零售业，经营范围包含：商品零售贸易（许可审批类商品除外）;化妆品及卫生用品零售;水晶饰品零售;办公设备耗材零售;玩具零售;婴儿用品零售;帽零售;鞋零售;箱、包零售;纺织品及针织品零售;服装零售;百货零售（食品零售除外）;家具零售;灯具零售;工艺美术品零售（象牙及其制品除外）;计算机零配件零售;电子产品零售;电子元器件零售;开关、插座、接线板、电线电缆、绝缘材料零售;陶瓷装饰材料零售;卫生洁具零售;陶瓷、玻璃器皿零售;厨房用具及日用杂品零售;日用杂品综合零售;日用灯具零售;互联网商品零售（许可审批类商品除外）;照相器材零售。郴州咖菲电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022537号-2',
    img: '',
    domain2: '',
  },
  {
    host: 'www.928daifa.com',
    content: '郴州市濮乐贸易有限公司成立于2019-12-27，统一社会信用代码为91360202MA393AWG9F。企业地址位于郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A10房，所属行业为批发业，经营范围包含：服装、鞋帽、床上用品、家具、箱包、体育用品（不含弩）、玩具、工艺品（不含文物、象牙及制品）、建筑材料（不含危险化学品）、日用品、化妆品、文具、五金产品、机械设备、皮革制品、餐具、陶瓷用品、电子产品、珠宝饰品、汽车零配件、家用电器、办公用品、预包装食品销售。（依法须经批准的项目,经相关部门批准后方可开展经营活动）。郴州市濮乐贸易有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022561号-2',
    img: '',
    domain2: '',
  },
  {
    host: 'www.8808daifa.com',
    content: '郴州昔飞贸易有限公司成立于2021-05-29，统一社会信用代码为91440101MA9XUC1E04。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A11房，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州昔飞贸易有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017254号-2',
    img: '',
    domain2: '',
    title: '郴州昔飞贸易有限公司',
  },
  {
    host: 'www.6608daifa.com',
    content: '郴州市百盈电子商务有限公司成立于2017-12-28，统一社会信用代码为91321302MA1UTP2T2K。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A08房，所属行业为零售业，经营范围包含：网络技术开发、推广、销售;软件技术开发;预包装食品 （不含冷藏冷冻食品）、散装食品（不含冷藏冷冻食品）销售（含网上销售）;初级农产品收购（粮食除外）;办公用品、体育用品、家具销售（含网上销售）;日用品、服装、鞋帽、床上用品、电子产品、美容电器设备、体育用品、保健食品销售（含网上销售）;图书零售。（依法须经批准的项目,经相关部门批准后方可开展经营活动）。郴州市百盈电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017365号-2',
    img: '',
    domain2: '',
    title: '郴州市百盈电子商务有限公司',
  },
  {
    host: 'www.6668daifa.com',
    content: '郴州帕维妍服装有限公司，统一社会信用代码为91420117MA49KQ5H3B。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A07房，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;软件开发（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州帕维妍服装有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017376号-2',
    img: '',
    domain2: '',
    title: '郴州帕维妍服装有限公司',
  },
  {
    host: 'www.tuilpw.com',
    content: '郴州市佰丽电子商务有限公司成立于2011-04-13，统一社会信用代码为91330782572933471R。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师15A01房，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市佰丽电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017440号-2',
    img: '',
    domain2: '',
    title: '郴州市佰丽电子商务有限公司',
  },
  {
    host: 'www.qidaifa.com',
    content: '郴州帕维妍服装有限公司成立于2020-09-29，统一社会信用代码为91420117MA49KQ5H3B。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A07房，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;软件开发（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州帕维妍服装有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017376号-3',
    img: '',
    domain2: '',
    title: '郴州帕维妍服装有限公司',
  },
  {
    host: 'www.19daifa.com',
    content: '郴州咖菲电子商务有限公司成立于2018-12-03，所属行业为零售业，经营范围包含：商品零售贸易（许可审批类商品除外）；化妆品及卫生用品零售；水晶饰品零售；办公设备耗材零售；玩具零售；婴儿用品零售；帽零售；鞋零售；箱、包零售；纺织品及针织品零售；服装零售；百货零售（食品零售除外）；家具零售；灯具零售；工艺美术品零售（象牙及其制品除外）；计算机零配件零售；电子产品零售；电子元器件零售；开关、插座、接线板、电线电缆、绝缘材料零售；陶瓷装饰材料零售；卫生洁具零售；陶瓷、玻璃器皿零售；厨房用具及日用杂品零售；日用杂品综合零售；日用灯具零售；互联网商品零售（许可审批类商品除外）；照相器材零售。',
    domain: '湘ICP备2022022537号-3',
    img: '',
    domain2: '',
    title: '郴州咖菲电子商务有限公司',
  },
  {
    host: 'www.678daifa.com',
    content: '郴州市濮乐贸易有限公司成立于2019-12-27，所属行业为批发业，经营范围包含：服装、鞋帽、床上用品、家具、箱包、体育用品（不含弩）、玩具、工艺品（不含文物、象牙及制品）、建筑材料（不含危险化学品）、日用品、化妆品、文具、五金产品、机械设备、皮革制品、餐具、陶瓷用品、电子产品、珠宝饰品、汽车零配件、家用电器、办公用品、预包装食品销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）。',
    domain: '湘ICP备2022022561号-3',
    img: '',
    domain2: '',
    title: '郴州市濮乐贸易有限公司',
  },
  {
    host: 'www.1188daifa.com',
    content: '郴州市润究电子商务有限公司成立于2020-07-27，所属行业为互联网和相关服务，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市润究电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022559号-3',
    img: '',
    domain2: '',
    title: '郴州市润究电子商务有限公司',
  },
  {
    host: 'www.daifa4.com',
    content: '郴州大帅哥贸易有限公司成立于2021-10-18，所属行业为零售业，经营范围包含：许可项目:互联网信息服务（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）一般项目:互联网销售（除销售需要许可的商品）;日用百货销售;鞋帽零售;箱包销售;钟表销售;眼镜销售（不含隐形眼镜）;针纺织品销售;服装辅料销售;皮革销售;皮革制品销售;化妆品零售;化妆品批发;个人卫生用品销售;家用电器销售;机械设备销售;五金产品批发;五金产品零售;橡胶制品销售;办公用品销售;体育用品及器材零售;体育用品及器材批发;工艺美术品及收藏品批发（象牙及其制品除外）;工艺美术品及礼仪用品销售（象牙及其制品除外）;玩具销售;珠宝首饰批发;珠宝首饰零售;灯具销售;建筑装饰材料销售;鲜肉零售;鲜肉批发;农副产品销售;宠物食品及用品批发;宠物食品及用品零售;渔具销售;户外用品销售;服装、服饰检验、整理服务;服装服饰批发;服装服饰零售;纸制品销售（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022023006号-2',
    img: '',
    domain2: '',
    title: '郴州大帅哥贸易有限公司',
  },
  {
    host: 'www.11daifa.com',
    content: '郴州市百盈电子商务有限公司成立于2017-12-28，统一社会信用代码为91321302MA1UTP2T2K。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A08房，所属行业为零售业，经营范围包含：网络技术开发、推广、销售;软件技术开发;预包装食品 （不含冷藏冷冻食品）、散装食品（不含冷藏冷冻食品）销售（含网上销售）;初级农产品收购（粮食除外）;办公用品、体育用品、家具销售（含网上销售）;日用品、服装、鞋帽、床上用品、电子产品、美容电器设备、体育用品、保健食品销售（含网上销售）;图书零售。（依法须经批准的项目,经相关部门批准后方可开展经营活动）。郴州市百盈电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017365号-3',
    img: '',
    domain2: '',
    title: '郴州市百盈电子商务有限公司',
  },
  {
    host: 'www.daifa345.com',
    content: '郴州乔玲晟商贸有限公司成立于2019-12-23，统一社会信用代码为91320303MA20NH2PXC。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师15A01，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州乔玲晟商贸有限公司目前的经营状态为存续（在营、开业、在册）',
    domain: '湘ICP备2023020032号-1',
    img: '',
    domain2: '',
    title: '郴州乔玲晟商贸有限公司',
  },
  {
    host: 'www.sxkj888.cn',
    content: '淅川县强星发百货店  经营范围包括零售：日用百货、家用电器、家居布艺、办公用品、户外用品、电子产品、数码产品、服装鞋帽、皮具箱包、化妆品、五金工具、儿童玩具、计算机软硬件开发销售',
    domain: '豫ICP备2021025246号-1',
    img: '',
    domain2: '',
    title: '淅川县强星发百货店',
  },
  {
    host: 'www.daifa3.com',
    content: '郴州市蓬橙贸易有限公司成立于2019-11-06，所属行业为批发业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。',
    domain: '湘ICP备2022022556号-3',
    img: '',
    domain2: '',
    title: '郴州市蓬橙贸易有限公司',
  },
  {
    host: 'www.1118daifa.com',
    content: '郴州市佰丽电子商务有限公司成立于2011-04-13， 企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师15A01房，所属行业为零售业，经营范围包含：一般事项:互联网销售（除销售需要许可的商品）;食品互联网销售（仅销售预包装食品）;信息技术咨询服务;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。郴州市佰丽电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2023017440号-3',
    img: '',
    domain2: '',
    title: '郴州市佰丽电子商务有限公司',
  },
  {
    host: 'www.88daifa.com',
    content: '经营范围: 其他互联网平台；软件开发；计算机领域内的技术服务、开发、咨询、转让；互联网工程施工、调试和维护；互联网运营和推广；广告业；计算机、软件及辅助设备批发兼零售；电子产品及配件、预包装食品、生鲜家禽、农产品、日用百货、服装、鞋帽、箱包的销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
    domain: '湘ICP备2023030517号-1',
    img: '',
    domain2: '',
    title: '郴州名扬科技有限公司',
  },
  {
    host: 'www.3daifa.com',
    content: '郴州名扬科技有限公司经营范围包括其他互联网平台；软件开发；计算机领域内的技术服务、开发、咨询、转让；互联网工程施工、调试和维护；互联网运营和推广；广告业；计算机、软件及辅助设备批发兼零售；电子产品及配件、预包装食品、生鲜家禽、农产品、日用百货、服装、鞋帽、箱包的销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
    domain: '湘ICP备2023030517号-2',
    img: '',
    domain2: '',
    title: '郴州名扬科技有限公司',
  },
  {
    host: 'www.daifa99.com',
    content: '郴州乔玲晟商贸有限公司。经营范围包括一般事项：互联网销售（除销售需要许可的商品）；食品互联网销售（仅销售预包装食品）；信息技术咨询服务；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）',
    domain: '湘ICP备2023020032号-2',
    img: '',
    domain2: '',
    title: '郴州乔玲晟商贸有限公司',
  },
  {
    host: 'www.33daifa.com',
    content: '郴州市濮乐贸易有限公司，。经营范围包括服装、鞋帽、床上用品、家具、箱包、体育用品（不含弩）、玩具、工艺品（不含文物、象牙及制品）、建筑材料（不含危险化学品）、日用品、化妆品、文具、五金产品、机械设备、皮革制品、餐具、陶瓷用品、电子产品、珠宝饰品、汽车零配件、家用电器、办公用品、预包装食品销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动',
    domain: '湘ICP备2022022561号-4',
    img: '',
    domain2: '',
    title: '郴州市濮乐贸易有限公司',
  },
  {
    host: 'www.daifa9.com',
    content: '郴州名扬科技有限公司。经营范围: 其他互联网平台；软件开发；计算机领域内的技术服务、开发、咨询、转让；互联网工程施工、调试和维护；互联网运营和推广；广告业；计算机、软件及辅助设备批发兼零售；电子产品及配件、预包装食品、生鲜家禽、农产品、日用百货、服装、鞋帽、箱包的销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
    domain: ' 湘ICP备2023030517号-4',
    img: '',
    domain2: '',
    title: '郴州名扬科技有限公司',
  },
  {
    host: 'www.99daifa.com',
    content: '郴州名扬科技有限公司。经营范围:其他互联网平台；软件开发；计算机领域内的技术服务、开发、咨询、转让；互联网工程施工、调试和维护；互联网运营和推广；广告业；计算机、软件及辅助设备批发兼零售；电子产品及配件、预包装食品、生鲜家禽、农产品、日用百货、服装、鞋帽、箱包的销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
    domain: ' 湘ICP备2023030517号-3',
    img: '',
    domain2: '',
    title: '郴州名扬科技有限公司',
  },
  {
    host: 'www.8daifa.com',
    content: '郴州市百盈电子商务有限公司成立于2017-12-28，统一社会信用代码为91321302MA1UTP2T2K。企业地址位于湖南省郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A08房，所属行业为零售业，经营范围包含：网络技术开发、推广、销售;软件技术开发;预包装食品 （不含冷藏冷冻食品）、散装食品（不含冷藏冷冻食品）销售（含网上销售）;初级农产品收购（粮食除外）;办公用品、体育用品、家具销售（含网上销售）;日用品、服装、鞋帽、床上用品、电子产品、美容电器设备、体育用品、保健食品销售（含网上销售）;图书零售。（依法须经批准的项目,经相关部门批准后方可开展经营活动）。郴州市百盈电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: ' 湘ICP备2023017365号-4',
    img: '',
    domain2: '',
    title: '郴州市百盈电子商务有限公司',
  },
  {
    host: 'www.55daifa.com',
    content: '郴州市咖菲电子商务有限公司成立于2018-12-03，统一社会信用代码为91440101MA5CK5E41H。企业地址位于郴州市苏仙区苏仙岭街道飞虹路龙腾广场生活大师16A03房，所属行业为零售业，经营范围包含：商品零售贸易（许可审批类商品除外）;化妆品及卫生用品零售;水晶饰品零售;办公设备耗材零售;玩具零售;婴儿用品零售;帽零售;鞋零售;箱、包零售;纺织品及针织品零售;服装零售;百货零售（食品零售除外）;家具零售;灯具零售;工艺美术品零售（象牙及其制品除外）;计算机零配件零售;电子产品零售;电子元器件零售;开关、插座、接线板、电线电缆、绝缘材料零售;陶瓷装饰材料零售;卫生洁具零售;陶瓷、玻璃器皿零售;厨房用具及日用杂品零售;日用杂品综合零售;日用灯具零售;互联网商品零售（许可审批类商品除外）;照相器材零售。郴州咖菲电子商务有限公司目前的经营状态为存续（在营、开业、在册）。',
    domain: '湘ICP备2022022537号-4',
    img: '',
    domain2: '',
    title: '郴州咖菲电子商务有限公司',
  },
  {
    host: '',
    content: '',
    domain: '',
    img: '',
    domain2: '',
  },
]

export default HOST_lIST