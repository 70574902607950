<template>
  <div class="footer">
    <div class="number" @click="openNewWindow">{{ content.domain }}</div>
    <div>{{ content.title }}</div>
  </div>
</template>

<script>
import HOST_LIST from '@/config/hostList';

export default {
  data() {
    return {
      content: {}
    }
  },
  methods: {
    openNewWindow() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  },
  created() {
    const currentHost = location.host.indexOf('www') != -1 ? location.host : 'www.' + location.host;
    
    HOST_LIST.forEach((item) => {
      if (item.host == currentHost) {
        this.content = item        
      }
    })

    console.log('location.host', currentHost, this.content)
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    // position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    font-size: 14px;
    background: #EEEEEE;
    & > div:last-child {
      margin-left: 70px;
    }
    .number {
      color: rgb(0, 157, 255);
      cursor: pointer;
    }
  }
</style>